const userMapping = {
    "FSINT030": "Sharon Flora",
    "FSINT027": "LAVAN J V",
    "FSINT026": "Ramanathan SP",
    "FSINT029": "Thanush B",
    "FSINT025": "Aathi Eswar S",
    "FSINT028": "Amritha Lakshmi N A",
    "FS-0196": "Ashish Sharma",
    "FS0195": "Nitesh Soni",
    "FS0194": "Thejo Varma Konduru",
    "WAX002": "Radha Anand",
    "FS - Con - 009": "Balaji Rengan",
    "FSINT024": "Vineesh Koppay",
    "WAX001": "Brian Gavin",
    "FS-Con-006": "Pradeep Kumar",
    "FSF028": "Sneha Manarsamy",
    "FS0188": "Adithya Ravi",
    "FS0197": "Akhila Sunil Kumar",
    "FS0198": "Harini Karthikeyan",
    "FS0200": "Shalini Raghuraja C",
    "FS0199": "Krithikaa Jothi Prakash",
    "FS0201": "Snehapriyaa Pramashivam",
    "FS0183": "Roopesh Dhananjayalu",
    "FS0179": "Priyanka Saravanan",
    "FS0176": "Manikandan P",
    "FS0174": "Sai Ram Muralikrishnan",
    "FS0189": "Abhilaash Jaishankar",
    "FSAcc01": "Accounts Dept",
    "FS0187": "Siva Kumar S",
    "FS0169": "Abhishek Kushwaha",
    "FS0116": "Pushparaj T",
    "FS-CEO-001": "Venu Moola",
    "FS-Con-002": "Vidyut Sorathiya",
    "FSF-002": "Munusamy Chandran",
    "FS0186": "Sai Ramya M",
    "FS0184": "Sanmukapriya Kamalakkannan",
    "FS0140": "Alex Surya Rayappan",
    "FS0114": "Arnab Sinha Roy",
    "FS0097": "Glory Victor",
    "FS0083": "Sathish Jayakumar",
    "FS0082": "Swathi Sridhar",
    "FS0162": "Isha Agarwal",
    "FS0190": "Komathi Sundhar",
    "FS0181": "Subash Kanagamani",
    "FS0010": "Naren Ethiraj",
    "FS0008": "Deepa Ramia Krishnamoorthy",
    "FS0159": "Thangaraj Manthiriyappan",
    "FS0056": "Rajeswari Kalisamy",
    "FS0069": "Shriram Thirukkumaran",
    "FS0005": "Latha Kumanan",
    "FSS003": "Office Helper"
  };
  
  export default userMapping