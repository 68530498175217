import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Button, Container, Paper, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { styled } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CircularProgress from "@mui/material/CircularProgress";
import { FiTable,FiGrid} from 'react-icons/fi';  // Import table icon from react-icons
import userMapping from '../utensils/Name';  // Import the mapping

// Function to get user profile photo or default image
const getUserPhoto = (userId) => {
    try {
        return require(`../assets/${userId}.jpg`);
    } catch (error) {
        return require('../assets/default.jpg');  // Fallback to default image
    }
};

// Styling with MUI system and custom CSS
const StyledContainer = styled(Container)({
    backgroundColor: "#FFFFFF",
    padding: "30px",
    borderRadius: "16px",
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    boxSizing: "border-box",

    // Add responsive behavior with media queries
    '@media (max-width: 1200px)': {
        maxWidth: "1000px",
    },
    '@media (max-width: 1000px)': {
        maxWidth: "800px",
    },
    '@media (max-width: 800px)': {
        maxWidth: "90%", // For small screens, use 90% of the screen width
    },
});



const Title = styled("h1")({
    textAlign: "center",
    color: "#0d47a1",
    marginBottom: "30px",
    fontSize: "2.5rem",
    fontFamily: "Roboto, sans-serif",
});

const UserListContainer = styled("div")({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', // This will allow 4 cards if screen size allows
    gap: '24px',
    marginTop: '20px',
    '@media (max-width: 1200px)': { // Adjust the layout when the screen width is smaller than 1200px
        gridTemplateColumns: 'repeat(3, 1fr)', // 3 cards for medium screens
    },
    '@media (max-width: 768px)': {
        gridTemplateColumns: 'repeat(2, 1fr)', // 2 cards for smaller screens
    },
    '@media (max-width: 480px)': {
        gridTemplateColumns: 'repeat(1, 1fr)', // 1 card for mobile devices
    },
});

const UserCard = styled(Paper)({
    display: 'flex',
    alignItems: 'center',
    padding: "24px",
    borderRadius: "8px",
    cursor: "pointer",
    border: "2px solid transparent",
    transition: "border 0.3s ease, transform 0.3s ease",
    '&:hover': {
        border: "2px solid #0d47a1",
        transform: 'scale(1.05)',
    },
});

const UserProfilePic = styled("img")({
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginRight: "16px",
});

const AttendanceByDate = () => {
    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedUserPunches, setSelectedUserPunches] = useState({});
    const [peopleCount, setPeopleCount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [isTableView, setIsTableView] = useState(false); // Toggle between card view and table view

    // Fetch attendance data on selectedDate change
    useEffect(() => {
        if (selectedDate) {
            fetchAttendanceData(selectedDate);
        }
    }, [selectedDate]);

    const fetchAttendanceData = async (date) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `https://reports-be.fleetstudio.com/api/all?startDateTime=${date}&endDateTime=${date}`
            );
            const data = response.data;

            const groupedByUser = data.reduce((acc, item) => {
                if (!acc[item.UserID]) {
                    acc[item.UserID] = [];
                }

                const punchTime = item.PunchTime.slice(11, 19);
                acc[item.UserID].push(punchTime);
                return acc;
            }, {});

            const formattedData = Object.entries(groupedByUser).map(([userId, punches]) => {
                const checkInTime = punches[0];
                const checkOutTime = punches.length > 1 ? punches[punches.length - 1] : "Not Available";

                return {
                    userId,
                    checkInTime,
                    checkOutTime,
                    punches: punches,  // Update this to be the count of punches
                };
            });

            setUsersData(formattedData);
            setPeopleCount(formattedData.length);  // Set the people count
        } catch (error) {
            console.error('Error fetching attendance data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Handle click on a user to show punches in a dialog
    const handleUserClick = (userId, punches, checkInTime, checkOutTime) => {
        const userName = userMapping[userId] || `Unknown User (${userId})`;  // Get user name
        setSelectedUserPunches({ userId, userName, punches, checkInTime, checkOutTime });
        console.log(selectedUserPunches)
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleTableViewToggle = () => {
        setIsTableView(!isTableView);  // Toggle between views
    };

    // Filter users by search term
    const filteredUsers = usersData.filter(({ userId }) => {
        const userName = userMapping[userId] || `Unknown User (${userId})`;
        return userName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    return (
        <StyledContainer>
            <Title sx={{margin:1}}>
                <h1 className="sticky top-0 mb-2 bg-white text-3xl font-extrabold text-gray-900 dark:text-black md:text-5xl lg:text-6xl">
                    <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
                        At the
                    </span> Desk
                </h1>
                <div style={{ borderBottom: '2px solid #0d47a1' }}></div>
            </Title>

            {/* Sticky Navbar */}
            <div className="sticky top-0 z-10 bg-white  p-4">
                <div className="flex items-center justify-between space-x-4">

                    {/* DatePicker container with custom width */}
                    <div className="flex-shrink-0" style={{ maxWidth: '200px' }}>
                        <DatePicker
                            label="Select Day"
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(dayjs(newValue).format('YYYY-MM-DD'))}
                            renderInput={(params) => (
                                <TextField {...params} fullWidth style={{ fontSize: '1.2em' }} />
                            )}
                        />
                    </div>

                    {/* Search bar container with increased width */}
                    <div className="flex-grow" style={{ maxWidth: '500px' }}>
                        <TextField
                            label="Search by Name"
                            variant="outlined"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            sx={{
                                backgroundColor: 'white',
                                borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ccc',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#888',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#0d47a1',
                                    },
                                },
                                width: '100%', // Full width within the container
                                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                            }}
                        />
                    </div>

                    {/* Right-aligned section with count and table icon */}
                    <div className="flex items-center space-x-4">
                        <div className="text-2xl font-bold bg-gradient-to-r from-black to-gray-700 text-transparent bg-clip-text">
                            Count: {peopleCount}
                        </div>
                        <div className="flex items-center">
  {/* Table View Icon */}
  <div className="relative inline-flex items-center justify-between bg-white rounded-full p-1 shadow-md w-32">
  {/* Table View Section */}
  <div
    className={`cursor-pointer flex items-center justify-center w-1/2 h-8 rounded-full transition-all duration-300 ${
      isTableView ? 'bg-blue-200' : ''
    }`}
    onClick={() => {
      if (!isTableView) handleTableViewToggle('table'); // Change to table view only if it's not already in table view
    }}
  >
    <FiTable
      size={24} // Adjusted size for smaller toggle
      className={`transition-colors duration-300 ${
        isTableView ? 'text-blue-600' : 'text-gray-600'
      }`}
    />
  </div>

  {/* Cards View Section */}
  <div
    className={`cursor-pointer flex items-center justify-center w-1/2 h-8 rounded-full transition-all duration-300 ${
      !isTableView ? 'bg-blue-200' : ''
    }`}
    onClick={() => {
      if (isTableView) handleTableViewToggle('cards'); // Change to cards view only if it's not already in cards view
    }}
  >
    <FiGrid
      size={24} // Adjusted size for smaller toggle
      className={`transition-colors duration-300 ${
        !isTableView ? 'text-blue-600' : 'text-gray-600'
      }`}
    />
  </div>
</div>

</div>

                    </div>
                </div>
            </div>

            {/* Main Content */}
            {loading ? (
                <p className="text-center text-lg font-medium text-blue-700">Loading data...</p>
            ) : filteredUsers.length > 0 ? (
                isTableView ? (
                    <div className="transition-container show">
                        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
                            <thead className="bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                                <tr>
                                    <th className="py-3 px-5 text-left text-sm font-semibold">Name</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold">Check-In Time</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold">Check-Out Time</th>
                                    <th className="py-3 px-5 text-left text-sm font-semibold">Punches</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map(({ userId, checkInTime, checkOutTime, punches }) => (
                                    <tr
                                        key={userId}
                                        onClick={() => handleUserClick(userId, punches, checkInTime, checkOutTime)}
                                        className="bg-white hover:bg-blue-100 transition duration-200 ease-in-out cursor-pointer"
                                    >
                                        <td className="py-3 px-5 border-b text-sm font-medium text-gray-900">
                                            {userMapping[userId] || `Unknown User (${userId})`}
                                        </td>
                                        <td className="py-3 px-5 border-b text-sm text-gray-700">
                                            {checkInTime}
                                        </td>
                                        <td className="py-3 px-5 border-b text-sm text-gray-700">
                                            {checkOutTime}
                                        </td>
                                        <td className="py-3 px-5 border-b text-sm text-gray-700">
                                            {punches.length}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <UserListContainer>
                    {filteredUsers.map(({ userId, checkInTime, checkOutTime, punches }) => (
                      <UserCard key={userId} onClick={() => handleUserClick(userId, punches, checkInTime, checkOutTime)}>
                        <UserProfilePic src={getUserPhoto(userId)} alt={`Profile picture of ${userId}`} />
                        <div>
                          <h3 className="font-bold text-lg text-blue-600">{userMapping[userId] || `Unknown User (${userId})`}</h3>
                          <p>
                            <span className="font-semibold text-black">Check-In:</span> {checkInTime}
                          </p>
                          <p>
                            <span className="font-semibold text-black">Check-Out:</span> {checkOutTime}
                          </p>
                          <p>
                            <span className="font-semibold text-black">Punches:</span> {punches.length}
                          </p>
                        </div>
                      </UserCard>
                    ))}
                  </UserListContainer>
                  
                  
                )
            ) : (
                <p className="text-center text-lg font-medium text-blue-700">No data found for the selected date.</p>
            )}

            {/* Dialog for showing user punches */}
            <Dialog
    open={openDialog}
    onClose={handleCloseDialog}
    PaperProps={{
        style: {
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            width: '470px', // Set the desired width of the dialog
        }
    }}
>
    <DialogTitle sx={{ padding: 0 }}> {/* Remove default padding */}
        <div
            className="bg-gradient-to-r from-[#3a97d2] via-[#2b7d99] to-[#1f5f77] text-white p-4 rounded-t-lg text-center"
        >
            {selectedUserPunches.userName} - Punch Times
        </div>
    </DialogTitle>

    <DialogContent className="bg-white rounded-b-lg p-4 mt-4"> {/* Add padding here */}
        <div className="space-y-2">
            <h3 className="text-lg font-semibold text-gray-700">
                Check-In: <span className="text-blue-600">{selectedUserPunches.checkInTime}</span>
            </h3>
            <h3 className="text-lg font-semibold text-gray-700">
                Check-Out: <span className="text-blue-600">{selectedUserPunches.checkOutTime}</span>
            </h3>

            <h3 className="text-lg font-semibold text-gray-700">Punch Times:</h3>
            <ul className="space-y-2 bg-white p-2 rounded-lg max-h-40 overflow-y-auto"> {/* Add max height and overflow */}
                {selectedUserPunches.punches && selectedUserPunches.punches.length > 0 ? (
                    selectedUserPunches.punches.map((punchTime, index) => (
                        <li
                            key={index}
                            className="bg-blue-100 text-blue-700 rounded-lg py-2 px-4 text-sm shadow-sm"
                        >
                            {punchTime}
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No punches recorded</li>
                )}
            </ul>
        </div>
    </DialogContent>

    <DialogActions>
        <Button
            onClick={handleCloseDialog}
            className="text-white hover:to-blue-500 transition-all duration-200 ease-in-out px-4 py-2 rounded-lg"
        >
            Close
        </Button>
    </DialogActions>
</Dialog>


        </StyledContainer>
    );
};

export default AttendanceByDate;
