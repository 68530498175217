import axios from 'axios';

// Base URL for API from environment variables
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchActiveUsers = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/active-users`);
        return response.data;
    } catch (error) {
        console.error('Error fetching active users:', error);
        return [];
    }
};

export const fetchUserTimesheetData = async (uid, min_date, max_date) => {
    try {
        const response = await axios.get(`${BASE_URL}/user-data-by-range`, {
            params: {
                uid,
                min_date,
                max_date,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching user timesheet data:', error);
        return [];
    }
};

export const fetchProjectData = async (pid, min_date, max_date) => {
    try {
        const response = await axios.get(`${BASE_URL}/project-data`, {
            params: {
                pid,
                min_date,
                max_date,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching project data:', error);
        return [];
    }
};

export const fetchDataByDateRange = async (min_date, max_date) => {
    try {
        const response = await axios.get(`${BASE_URL}/data-by-range`, {
            params: {
                min_date,
                max_date,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching data by date range:', error);
        return [];
    }
};


export const fetchProjects = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/projects`);
        return response.data;
    } catch (error) {
        console.error('Error fetching projects:', error);
        return [];
    }
};
