import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utensils/AuthContext"; // Import the useAuth hook
import { BASE_URL } from "../constants/constants"
import { FcGoogle } from "react-icons/fc";
import ProgressLoader from "../utensils/ProgressLoader";
import GoogleSignin from "../auth/GoogleSignIn";
// import GoogleSignIn from '../auth/GoogleSignIn';

const LoginComponent = () => {
  const [email, setEmail] =   useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const {
    login,
    isAdmin,
    isProjectManager,
    isOtherUser,
    setIsAdmin,
    setIsProjectManager,
    setIsOtherUser,
  } = useAuth(); // Get the login function from context

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error before attempting to log in
    setError("");

    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/api/user/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const { firstName, token, roles, _id, appRoles, permissions } =
          response.data;
        localStorage.setItem("firstName", firstName);
        localStorage.setItem("token", token);
        localStorage.setItem("uid", _id);
        localStorage.setItem("appRoles", _id);
        localStorage.setItem("roles", JSON.stringify(roles)); // Store roles as JSON
        localStorage.setItem("permissions", JSON.stringify(permissions)); // Store roles as JSON
        localStorage.setItem("appRoles", JSON.stringify(appRoles)); // Store roles as JSON

        // Update auth context
        login(response.data);

        if (response?.data?.appRoles?.includes("Project Manager")) {
          setIsProjectManager(true);
        } else if (response?.data?.appRoles?.includes("Admin")) {
          setIsAdmin(true);
        } else {
          setIsOtherUser(true);
        }
        // Redirect based on role
        if (roles.includes("ADMIN")) {
          setIsAdmin(true);
          navigate("/dashboard/project-analysis");
        } else {
          navigate("/login");
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-100">
      {/* <ProgressLoader /> */}
      {/* Background image */}

      <div className="w-1/2 bg-[#00AAFF] h-[100vh] flex justify-center items-center shadow-xl">
        <img
          src={require("../img/logo1.png")}
          alt=""
          srcset=""
          className=""
        />
      </div>
      {/* Login Form */}
      <div className="w-1/2 flex justify-center items-center">
        <div className="relative bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-8 lg:mx-0 z-10">
          <h2 className="text-2xl font-bold text-center mb-6">Login</h2>

          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="••••••••"
              />
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <input
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember_me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div>
                <Link
                  to="/reset"
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <button
              type="submit"
              className="w-full outline outline-1 text-[#00AAFF] hover:bg-[#00AAFF] hover:text-white py-2 px-4 rounded-md hover:scale-[102%]  transition ease-in-out delay-100   hover:-translate-y-1   duration-150"
            >
              Sign In
            </button>
          </form>
<GoogleSignin/>
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;